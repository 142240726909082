
import Datatable from "../../../utils/datatable/datatable";
import router from '../../../router'
import { bus } from "../../../main";
import {
  addParamsToLocation,
  currency,
  getParamsFromLocation,
} from "../../../utils/helpers";
import AppForm from "../../../utils/form/components/Form.vue";
import Vue from "vue";
import { HeaderInterface } from "../header/headerInterface";
import { UsersExcel } from "../../../repositories/user";
export default Vue.extend({
  props: {
    table: Datatable,
  },
  data() {
    return {
      approvedServiceId: 0,
      msgModal: false,
      page: 1,
      itemsPerPage: 10,
      pageCount: 0,

      dialog: false,
      resp: {},
      msg: "",
    };
  },
  components: {
    AppForm,
  },
  computed: {
    totalsHeaders() {
      return this.table.headers.filter((header: HeaderInterface) => {
        return header.isPrice ? header : "";
      });
    },
    hasCategories(){
      return this.table.title == 'videos' ||  this.table.title == 'projects' ||  this.table.title == 'articles' ||  this.table.title == 'events'
    },
    hasCities(){
      return   this.table.title == 'projects' ||  this.table.title == 'events'
    },

    from() {
      return (this.$data.page - 1) * this.$data.itemsPerPage + 1;
    },
    to() {
      const from = (this.$data.page - 1) * this.$data.itemsPerPage;
      const to = from + this.$data.itemsPerPage;
      return to > this.table.data.length ? this.table.data.length : to;
    },
  },
  watch: {
    "table.filters.state": {
      handler(newValue) {
        addParamsToLocation(newValue, router.currentRoute.path);
        this.filter();
      },
      deep: true,
    },
  },
  methods: {
    fireGlobalAction(item){
      item.action(this)
    },
    advancedSearch(event: any, key: string) {},
    currency: (x: number) => currency(x),
    filter() {
      // reset headers totals to avoid sum bug
      // if we dont do this the class will add the totals to thee preevios data totals
      this.table.headers.forEach((header: HeaderInterface) => {
        header.total = 0;
      });
      this.table.getData();
    },
    showTotals() {
      this.dialog = true;
    },
  },
  created() {
    if (this.table.hasFilters && this.table.filters) {
      getParamsFromLocation(router.currentRoute.query, this.table.filters);
    }
    bus.$on("getTableData", () => {
      this.table.getData();
    });
  },
});
